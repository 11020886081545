import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import { useSelector } from "react-redux";
const PassengerHome = React.lazy(() => import("../../pages/passenger/home/Home"));
const StationDetail = React.lazy(() => import("../../pages/passenger/stationDetail/StationDetail"));
const NearByPlaces = React.lazy(() => import("../../pages/passenger/stationDetail/NearByPlaces"));
const Profile = React.lazy(() => import("../../pages/corporate/recruitment/Profile"));
const ProfileSuccess = React.lazy(() => import("../../pages/corporate/recruitment/ProfileSuccess"));
const KnowStation = React.lazy(() => import("../../pages/passenger/knowStation/KnowStation"));
const Map = React.lazy(() => import("../../pages/passenger/map/Map"));
const Content = React.lazy(() => import("../../pages/passenger/content/Content"));
const Notice = React.lazy(() => import("../../pages/passenger/notice/Notice"));
const ContactUs = React.lazy(() => import("../../pages/common/contactUs/ContactUs"));
const CommingSoon = React.lazy(() => import("../../pages/common/comingSoon/ComingSoon"));
const LostFound = React.lazy(() => import("../../pages/passenger/lostFound/LostFound"));
const Complaint = React.lazy(() => import("../../pages/passenger/complaint/Complaint"));
const Line = React.lazy(() => import("../../pages/passenger/line/Line"));
const Search = React.lazy(() => import("../../pages/passenger/search/Search"));
const Tours = React.lazy(() => import("../../pages/passenger/tours/Tours"));
const WifiService = React.lazy(() => import("../../pages/common/wifiservice/WifiService"));
const ScreenReaderAccess = React.lazy(() => import("../../pages/common/screenReaderAccess/ScreenReaderAccess"));
const SiteMap = React.lazy(() => import("../../pages/common/sitemap/SiteMap"));
const AboutUs = React.lazy(() => import("../../pages/common/aboutUs/AboutUs"));

const Main = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const lang: string = useSelector((state: any) => state.ui.lang);

    return (
        <>
            <Routes>
                <Route element={<MainLayout />}>
                    {/* passenger */}
                    <Route path="/" element={<PassengerHome />} />
                    <Route path="/:lang" element={<PassengerHome />} />
                    <Route path="/fare-recharge" element={<PassengerHome popup="fare-recharge" />} />
                    <Route path="/:lang/fare-recharge" element={<PassengerHome popup="fare-recharge" />} />
                    <Route path="/know-station" element={<KnowStation />} />
                    <Route path="/:lang/know-station" element={<KnowStation />} />
                    <Route path="/station/:code" element={<StationDetail />} />
                    <Route path="/:lang/station/:code" element={<StationDetail />} />
                    <Route path="/nearby-places/:code" element={<NearByPlaces />} />
                    <Route path="/:lang/nearby-places/:code" element={<NearByPlaces />} />
                    <Route path="/nearby-places" element={<NearByPlaces />} />
                    <Route path="/:lang/nearby-places" element={<NearByPlaces />} />
                    <Route path="/line/:code" element={<Line />} />
                    <Route path="/:lang/line/:code" element={<Line />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/:lang/map" element={<Map />} />
                    <Route path="/passenger/pages/:slug" element={<Content />} />
                    <Route path="/:lang/passenger/pages/:slug" element={<Content />} />
                    <Route path="/notice" element={<Notice />} />
                    <Route path="/:lang/notice" element={<Notice />} />
                    <Route path="/tours" element={<Tours />} />
                    <Route path="/:lang/tours" element={<Tours />} />
                    <Route path="/search/:search" element={<Search />} />
                    <Route path="/:lang/search/:search" element={<Search />} />
                    <Route path="/contact-us" element={<ContactUs type="passenger" />} />
                    <Route path="/:lang/contact-us" element={<ContactUs type="passenger" />} />
                    <Route path="/lost-found" element={<LostFound />} />
                    <Route path="/:lang/lost-found" element={<LostFound />} />
                    <Route path="/lost-found/report-lost-item" element={<LostFound popup="report-lost-item" />} />
                    <Route path="/:lang/lost-found/report-lost-item" element={<LostFound popup="report-lost-item" />} />
                    <Route path="/complaint" element={<Complaint />} />
                    <Route path="/:lang/complaint" element={<Complaint />} />
                    <Route path="/complaint/:id" element={<Complaint popup="complaint" />} />
                    <Route path="/:lang/complaint/:id" element={<Complaint popup="complaint" />} />
                    <Route path="/screen-reader-access" element={<ScreenReaderAccess type="passenger" />} />
                    <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess type="passenger" />} />
                    <Route path="/site-map" element={<SiteMap type="passenger" />} />
                    <Route path="/:lang/site-map" element={<SiteMap type="passenger" />} />
                    <Route path="/about-us" element={<AboutUs type="passenger" />} />
                    <Route path="/:lang/about-us" element={<AboutUs type="passenger" />} />
                    <Route path="/:lang/complaint/:id" element={<Complaint popup="complaint" />} />
                    <Route path="/coming-soon" element={<CommingSoon type="passenger" />} />
                    <Route path="/:lang/coming-soon" element={<CommingSoon type="passenger" />} />

                    {/* common-auth */}
                    {isLogin && <>
                        <Route path="/create-profile" element={<Profile />} />
                        <Route path="/:lang/create-profile" element={<Profile />} />
                        <Route path="/:lang/profile" element={<Profile />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/profile-success" element={<ProfileSuccess />} />
                        <Route path="/:lang/profile-success" element={<ProfileSuccess />} />
                    </>}
                </Route>
                <Route path="/wifi-service" element={<WifiService />} />
                <Route path="/:lang/wifi-service" element={<WifiService />} />
                <Route path="*" element={<Navigate to={`/${lang ? lang : "en"}`} />} />
            </Routes>
        </>
    );
};

export default Main;